.float-container {
    border: 3px solid #fff;
    padding: 20px;
}

.float-child {
    width: 50%;
    float: left;
    padding: 20px;
    border: 2px solid red;
}

.map {
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

/* https://coder-coder.com/display-divs-side-by-side/ */